import styles from './index.scss';

/**
 * This element simply positions its contents `fixed` on the bottom, full width, and only when
 * less than 768px; otherwise, its flow is `static`.
 *
 * @todo react to orientation changes
 */
class StickyCta extends HTMLElement {
  private mediaQuery: MediaQueryList;
  private matchesMediaQuery = false;
  private refElement?: HTMLElement;
  private setStickyPositionBound = this.setStickyPosition.bind(this);
  private updateElementsBasedOnViewportBound = this.updateElementsBasedOnViewport.bind(this);
  private initialized = false;

  readonly dataset!: {
    /**
     * Whether the element is currently in sticky mode or not. If not, then the mode is static.
     */
    stick: 'false' | 'true';
  };

  constructor() {
    super();
    this.attachShadow({ mode: 'open' });
    this.shadowRoot.innerHTML = `<style>${styles}</style><slot></slot>`;
  }

  public connectedCallback() {
    // Previously we initialized once in the constructor. However, that is forbidden. To preserve
    // that behavior we defer until connected. For the time being, even though it may not be
    // necessary, we still only init once.
    if (!this.initialized) {
      if (window.matchMedia) {
        this.mediaQuery = matchMedia('(max-width: 767px)');
      }

      this.initialized = true;
    }

    this.refElement = document.createElement('div');
    this.insertAdjacentElement('beforebegin', this.refElement);
    addEventListener('scroll', this.setStickyPositionBound, { passive: true });

    // Support older ios deprecated version
    if (this.mediaQuery) {
      // eslint-disable-next-line @typescript-eslint/no-deprecated
      if (this.mediaQuery.addListener) {
        // eslint-disable-next-line @typescript-eslint/no-deprecated
        this.mediaQuery.addListener(this.updateElementsBasedOnViewportBound);
      } else {
        this.mediaQuery.addEventListener('change', this.updateElementsBasedOnViewportBound);
      }
    }

    this.updateElementsBasedOnViewport();
  }

  public disconnectedCallback() {
    removeEventListener('scroll', this.setStickyPositionBound);
    // Support older ios deprecated version
    if (this.mediaQuery) {
      // eslint-disable-next-line @typescript-eslint/no-deprecated
      if (this.mediaQuery.addListener) {
        // eslint-disable-next-line @typescript-eslint/no-deprecated
        this.mediaQuery.removeListener(this.updateElementsBasedOnViewportBound);
      } else {
        this.mediaQuery.removeEventListener('change', this.updateElementsBasedOnViewportBound);
      }
    }
  }

  private setStickyPosition() {
    const offset = this.refElement.getBoundingClientRect().top +
      this.getBoundingClientRect().height;

    if (offset <= innerHeight) {
      this.setAttribute('data-stick', 'true');
    } else {
      this.removeAttribute('data-stick');
    }
  }

  private updateElementsBasedOnViewport() {
    if (this.mediaQuery) {
      if (this.mediaQuery.matches === this.matchesMediaQuery) {
        return;
      }

      this.matchesMediaQuery = this.mediaQuery.matches;
    }

    const elements = this.querySelectorAll('add-to-cart');
    for (const element of elements) {
      element.dataset.full = this.matchesMediaQuery ? 'true' : 'false';
    }
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'sticky-cta': StickyCta;
  }
}

if (!customElements.get('sticky-cta')) {
  customElements.define('sticky-cta', StickyCta);
}
